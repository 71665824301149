<div id="login-form" class="dropdown">
	<div class="dropdown-header text-center">
    <img *ngIf="isRPConnected" src="https://file-storage.sfo3.cdn.digitaloceanspaces.com/loginWithRP.png" class="link" (click)="openHelp()">
    <span *ngIf="!isRPConnected">LOGIN TO YOUR ACCOUNT</span>
  </div>
	<div class="dropdown-content p-12">
		<form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
			<!-- EMAIL -->
			<mat-form-field appearance="outline">
				<mat-label>Email</mat-label>
				<input type="email" matInput formControlName="email" #email id="email" autocomplete="email" [email]="true"/>
				<mat-icon matSuffix>markunread</mat-icon>
				<mat-hint class="red-fg" *ngIf="!loginForm.get('email').value">Email is <strong>required</strong></mat-hint>
				<mat-hint class="red-fg font-size-12" *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').invalid"><b>Invalid email address</b></mat-hint>
			</mat-form-field>
			<!-- PASSWORD -->
			<mat-form-field class="" appearance="outline">
				<button (click)="isVisible = !isVisible" type="button" class="mat-icon-button cdk-focused cdk-mouse-focused" mat-icon-button matRippleCentered="true" matRipple matSuffix>
					<mat-icon>{{isVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
				</button>
				<mat-label>Password</mat-label>
				<input class="form-control" [type]="isVisible ? 'text' : 'password'" matInput #pass id="pass" formControlName="pass" minlength="8" required autocomplete="new-password">
				<tinc-icon class="secondary-text" icon="key"></tinc-icon>
				<mat-hint class="red-900-fg bold" *ngIf="loginForm.get('pass').hasError('required')">Password is required</mat-hint>
				<mat-hint class="red-900-fg bold" *ngIf="loginForm.get('pass').hasError('minlength')">Password must be at least 8 characters long</mat-hint>
			</mat-form-field>
			<!-- FORGOT PASSWORD -->
			<div class="remember-forgot-password" [ngStyle]="{'flex-direction' : isMobile ? 'column' : 'row'}"  fxLayoutAlign="end center">
				<a class="forgot-password" (click)="closePopoverRef()" [routerLink]="'/auth/forgot-password'">Forgot Password?</a>
			</div>
			<div *ngIf="errorMessage" class="message-box error mb-12">Your email address or password is incorrect, please try again!</div>

			<!-- LOGIN BUTTON -->
			<button mat-raised-button class="primary submit-button" type="submit" aria-label="LOG IN" [disabled]="!loginForm.valid">LOGIN</button>
		</form>

		<!--
			<div class="separator">
				<span class="text">OR</span>
			</div>

			<button mat-raised-button class="google">
				Log in with Google
			</button>

			<button mat-raised-button class="facebook">
				Log in with Facebook
			</button> -->

		<div class="register" fxLayout="column" fxLayoutAlign="center center">
			<a class="text red-fg link" (click)="go2Register()">Don't have an account?</a>
			<a class="link primary-fg" (click)="go2Register()">Create an account</a>
		</div>
	</div>
</div>
